import Backdrop from "./Backdrop";
import MainNav from "./MainNav";



export default function ContactPage(props) {
    return (
        <>
        <MainNav />
        <Backdrop>
                
        </Backdrop>
        </>
    )
}